import { createSlice, current } from "@reduxjs/toolkit";
import { foundationProfileApi } from "Services/FoundationProfileService";
import { notesApi } from "Services/NoteService";
import { countryApi } from "Services/CountryService";
import { sectorApi } from "Services/SectorService";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { searchEngineApi } from "Services/SearchEngineService";
import config from "config";

export const initialState = {
    openPopupNote: false,
    noteData: {
        id: null,
        title: '',
        description: '',
        notificationDate: null,
        emailNotification: false,
        emails:[]
    },
    loadingGifts: false,
    applyFilter: '',
    init: false,
    profileName: '',
    lastReportYear: null,
    registerYear: null,
    stateOrProvince: '',
    organizationRegion: '',
    city: '',
    onlineApplication: '',
    website: '',
    nationalGivingHistory: '',
    phoneNumber: '',
    fullAddress: '',
    nteeTitle: '',
    nteeDescription: '',
    recipientName: '',
    countryList: [],
    provinceOrStateList: [],
    provinceOrStateListTemp: [],
    cityList: [],
    sectorList: [],
    countyList: [],
    stemmingSearch: true,
    contactEmail: '',
    applicationData: {
        foundationId: null
    },
    programData: {
        foundationId: null
    },
    yearList: [
        ...Array(config.searchEngineYearFilter.US - 2014 + 1)
        .fill()
        .map((_, idx) => ({
            index: idx,
            key:  (2014 + idx).toString(),
            value: (2014 + idx).toString(),
            label: (2014 + idx).toString(),
            checked: false
        }))
    ],
    summary: {
        avgGrantSize: null,
        totalAsserts: null,
        avgGrantsAnnually: null,
        grantsState: null,
        avgAmount: null,
        largestAmount: null,
        nationalGivingHistory: null,
        medianAmount: null,
        range: null,
        customAnalysisList: [],
        foundationFinancialList: [],
        yearAnalysisList: []
    },
    giftList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'donationdate',
        orderDirection: 'desc',
        items: []
    },
    grantOverviewList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'category',
        orderDirection: 'desc',
        items: [
            { category: 'Animal Welfare', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Art & Culture', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Community', grants: '50', largest: '$200,00', average: '$2,000', score: '3', grantamount: '$300,00' },
            { category: 'Education', grants: '333', largest: '$1,317,386,921', average: '$3,958,476', score: '5', grantamount: '$1,318,172,382' },
            { category: 'Environment', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Health', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Religion', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Social & Human Services', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Sports & Recreation', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'TOTAL', grants: '383', largest: '-', average: '-', score: '-', grantamount: '$1,318,472,382' },   
        ]
    },
    directorList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'directorname',
        orderDirection: 'desc',
        items: []
    },
    noteList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'createdAt',
        orderDirection: 'desc',
        items: []
    },
    charts: {
        provinceList: [],
        rangeAmount: [],
        rangeSector: {}
    },
    toggleExpandFilters: {
        provinceOrState: false,
        city: false,
        year: false,
        stemmingSearch: false,
        county: false
    },
    taxReturnPdf: []
    
};

const foundationProfileSlice = createSlice({
    name: "foundation-profile",
    initialState,
    reducers: {
        init: (state, action) => {
            state.init = true;
        },
        handleChangeCountry: (state, action) => {
            state.countryList.forEach(country => {
                if (country.code === action.payload.code) {
                    country.checked = action.payload.checked;
                }
            });
        },
        handleChangeProvinceOrState: (state, action) => {            
            action.payload.forEach(item => {
                const togglelistItem = state.provinceOrStateList.find(o => o.code === item.value);
                
                if (togglelistItem) {
                    togglelistItem.checked = item.checked;
                }
    
            });
        },
        handleChangeCity: (state, action) => {

            action.payload.forEach(item => {
                const togglelistItem = state.cityList.find(o => o.name === item.value);
                
                if (togglelistItem) {
                    togglelistItem.checked = item.checked;
                }
    
            });
        },
        handleChangeCounty: (state, action) => {

            action.payload.forEach(item => {
                const togglelistItem = state.countyList.find(o => o.value === item.value);
                
                if (togglelistItem) {
                    togglelistItem.checked = item.checked;
                }
    
            });
        },
        handleChangeYears: (state, action) => {
            state.yearList.forEach(year => {
                if (year.value === action.payload.value) {
                    year.checked = action.payload.checked;
                }
            });
        },
        handleChangeSector: (state, action) => {
            state.sectorList.forEach(sector => {
                if (sector.value === action.payload.value) {
                    sector.checked = action.payload.checked;
                }
            });
        },
        handleChangeRecipientName: (state, action) => {
            state.recipientName = action.payload;
        },
        handleAddNewNote: (state, action) => {
            state.openPopupNote = true;
            state.noteData = {...initialState.noteData};
        },
        handleEditNote: (state, action) => {
            state.openPopupNote = true;
            state.noteData.id = action.payload.id;
            state.noteData.title = action.payload.title;
            state.noteData.description = action.payload.description;
            state.noteData.notificationDate = action.payload.notificationDate;
            state.noteData.emailNotification = action.payload.emailNotification;
            state.noteData.emails = action.payload.emails;
        },
        handleClosePopupNote: (state, action) => {
            state.openPopupNote = false;
            state.noteData = {...initialState.noteData};
        },
        handleChangeStemmingSearch: (state, action) => {
            state.stemmingSearch = action.payload;
        },
        handleClear: (state, action) => {
            state.openPopupNote = false;
            state.noteData = { ...initialState.noteData };
            state.profileName = '';
            state.lastReportYear = null;
            state.lastReportYear = null;
            state.registerYear = null;
            state.stateOrProvince = '';
            state.organizationRegion = '';
            state.city = '';
            state.onlineApplication = '';
            state.website = '';
            state.nationalGivingHistory = '';
            state.phoneNumber = '';
            state.fullAddress = '';
            state.recipientName = '';
            state.stemmingSearch = true;
            state.yearList = [
                ...Array(config.searchEngineYearFilter.US - 2014 + 1)
                .fill()
                .map((_, idx) => ({
                    index: idx,
                    key:  (2014 + idx).toString(),
                    value: (2014 + idx).toString(),
                    label: (2014 + idx).toString(),
                    checked: false
                }))
            ];
            state.summary = { ...initialState.summary };
            state.charts = { ...initialState.charts };
            state.giftList = { ...initialState.giftList };
            state.countryList = [];
            state.provinceOrStateList = [];
            state.cityList = [];
            state.countyList = [];
            state.sectorList = [];
            state.stemmingSearch = true;
        },
        handleApplyFilter: (state, action) => {
            state.applyFilter = action.payload;
        },
        handleClearCityList: (state, action) => {
            state.cityList = [];
        },
        handleClearGifts: (state, action) => {

            state.provinceOrStateList = state.provinceOrStateList.map(item => ({
                ...item,
                checked: false
            }));

            state.cityList = state.cityList.map(item => ({
                ...item,
                checked: false
            }));

            state.countyList = state.countyList.map(item => ({
                ...item,
                checked: false
            }));

            state.sectorList = state.sectorList.map(item => ({
                ...item,
                checked: false
            }));

            state.yearList = state.yearList.map(item => ({
                ...item,
                checked: false
            }));

            state.applyFilter = action.payload;
            state.stemmingSearch = true;
            state.toggleExpandFilters = { ...initialState.toggleExpandFilters };
            
            state.giftList.pageNumber = 0;
            state.giftList.pageSize = 10;
            state.giftList.orderField = 'donationdate';
            state.giftList.orderDirection = 'desc';
        },
        handleToggleExpandFilters: (state, action) => {
            state.toggleExpandFilters = {
                ...state.toggleExpandFilters,
                [action.payload.id]: action.payload.value 
            }
        },
        handleClearCountyAndCity: (state, action) => {
            state.countryList = [];
            state.cityList = [];
        },
        handleUpdateNoteItem: (state, action) => {
            var noteItem = state.noteList.items?.find(o => o.id === action.payload.id);
            if(noteItem) {
                noteItem[action.payload.key] = action.payload.value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            foundationProfileApi.endpoints.getGiftsPagedList.matchFulfilled, (state, action) => {
                state.loadingGifts = false;
                state.giftList.pageNumber = action.payload.pageNumber;
                state.giftList.pageSize = action.payload.pageSize;
                state.giftList.pageCount = action.payload.pageCount;
                state.giftList.totalCount = action.payload.totalCount;
                state.giftList.orderField = action.payload.orderField;
                state.giftList.orderDirection = action.payload.orderDirection;
                state.giftList.items = action.payload.items;

                if (state.provinceOrStateListTemp.length === state.provinceOrStateList.length) {

                    const newStateList = [];

                    state.provinceOrStateListTemp.forEach(element => {
    
                        const existsOnAggr = action.payload.aggrFilters?.statesFilter?.find(o => o.key.toUpperCase() === element.code.toUpperCase());
    
                        if (existsOnAggr) {
                            const isChecked = state.provinceOrStateList.find(o => o.code === element.code)?.checked ?? false;
                            
                            newStateList.push({
                                ...element,
                                checked: isChecked
                            });
                        }
    
                    });
    
                    state.provinceOrStateList = newStateList;
                } 
            }
        )
        .addMatcher(
            foundationProfileApi.endpoints.getGiftsPagedList.matchPending, (state, action) => {
                state.loadingGifts = true;
            }
        )
        .addMatcher(
            foundationProfileApi.endpoints.getGiftsPagedList.matchRejected, (state, action) => {
                state.loadingGifts = false;
            }
        )
        .addMatcher(
            foundationProfileApi.endpoints.getGrantOverview.matchFulfilled, (state, action) => {
                state.grantOverviewList.pageNumber = action.payload.pageNumber;
                state.grantOverviewList.pageSize = action.payload.pageSize;
                state.grantOverviewList.pageCount = action.payload.pageCount;
                state.grantOverviewList.totalCount = action.payload.totalCount;
                state.grantOverviewList.items = action.payload.items;
            }
        )
        .addMatcher(
            foundationProfileApi.endpoints.getDirectorList.matchFulfilled, (state, action) => {
                state.directorList.pageNumber = action.payload.pageNumber;
                state.directorList.pageSize = action.payload.pageSize;
                state.directorList.pageCount = action.payload.pageCount;
                state.directorList.totalCount = action.payload.totalCount;
                state.directorList.orderField = action.payload.orderField;
                state.directorList.orderDirection = action.payload.orderDirection;
                state.directorList.items = action.payload.items;
            }
        )
        .addMatcher(
            notesApi.endpoints.getNoteList.matchFulfilled, (state, action) => {
                state.noteList.pageNumber = action.payload.pageNumber;
                state.noteList.pageSize = action.payload.pageSize;
                state.noteList.orderField = action.payload.orderField;
                state.noteList.orderDirection = action.payload.orderDirection;
                state.noteList.pageCount = action.payload.pageCount;
                state.noteList.totalCount = action.payload.totalCount;
                state.noteList.items = action.payload.items;
            }
        )
        .addMatcher(
            foundationProfileApi.endpoints.getProfileHeader.matchFulfilled, (state, action) => {
                state.profileName = action.payload.profileName;
                state.lastReportYear = action.payload.lastReportYear;
                state.registerYear = action.payload.registerYear;
                state.stateOrProvince = action.payload.stateOrProvince;
                state.organizationRegion = action.payload.organizationRegion;
                state.city = action.payload.city;
                state.onlineApplication = action.payload.onlineApplication;
                state.website = action.payload.website;
                state.nationalGivingHistory = action.payload.nationalGivingHistory;
                state.phoneNumber =  formatPhoneNumber(action.payload.phoneNumber);
                state.nationalGivingHistory = action.payload.nationalGivingHistory;
                state.fullAddress = action.payload.fullAddress;
                state.nteeTitle = action.payload.nteeTitle;
                state.nteeDescription = action.payload.nteeDescription;
                state.contactEmail = action.payload.contactEmail;

                state.summary.avgGrantSize = action.payload.summary.avgGrantSize;
                state.summary.totalAsserts = action.payload.summary.totalAsserts;
                state.summary.avgGrantsAnnually = action.payload.summary.avgGrantsAnnually;
                state.summary.grantsState = action.payload.summary.grantsState;

                state.summary.avgAmount = action.payload.summary.avgAmount;
                state.summary.largestAmount = action.payload.summary.largestAmount;
                state.summary.medianAmount = action.payload.summary.medianAmount;
                state.summary.range = action.payload.summary.range;

                state.summary.yearAnalysisList = action.payload.summary.yearAnalysisList;
            }
        )
        .addMatcher(
            countryApi.endpoints.getCountryByCodeWithTrigger.matchFulfilled, (state, action) => {

                state.countryList = [{
                    ...action.payload,
                    checked: true
                }];

                state.provinceOrStateList = [];
                state.provinceOrStateListTemp = [];

                action.payload.provinceOrStateList.forEach(provinceOrState => {
                    state.provinceOrStateList.push({
                        ...provinceOrState,
                        checked: false
                    });

                    state.provinceOrStateListTemp.push({
                        ...provinceOrState,
                        checked: false
                    });
                });
            }
        )
        .addMatcher(
            countryApi.endpoints.getCityListByProvinceOrStates.matchFulfilled, (state, action) => {
                state.cityList = action.payload.map(item => ({
                    ...item,
                    checked: state.cityList.find(o => o.name === item.name)?.checked ?? false
                }));
            }
        )
        .addMatcher(
            sectorApi.endpoints.getSectorList.matchFulfilled, (state, action) => {
                state.sectorList = action.payload.map(item => ({
                    ...item,
                    value: item.sectorName,
                    checked: false,
                }));
            }
        )
        .addMatcher(
            foundationProfileApi.endpoints.getChartProvinceList.matchFulfilled, (state, action) => {
                state.charts.provinceList = action.payload;
            }
        )
        .addMatcher(
            foundationProfileApi.endpoints.getChartRangeAmountList.matchFulfilled, (state, action) => {
                state.charts.rangeAmount = action.payload;
            }
        )
        .addMatcher(
            foundationProfileApi.endpoints.getChartRangeSector.matchFulfilled, (state, action) => {
                state.charts.rangeSector = action.payload;
            }
        )
        .addMatcher(
            searchEngineApi.endpoints.getCounties.matchFulfilled, (state, action) => {
                state.countyList = action.payload.map(county => ({
                    key: county.key,
                    value: county.key,
                    label: county.label,
                    provinceOrState: county.provinceOrStateCode,
                    checked: state.countyList.find(o => o.key === county.key)?.checked ?? false
                }));
            }
        )
    }
});

export const { 
    init,
    handleChangeCountry,
    handleChangeProvinceOrState,
    handleChangeCity,
    handleChangeSector,
    handleChangeRecipientName,
    handleAddNewNote,
    handleEditNote,
    handleClosePopupNote,
    handleChangeStemmingSearch,
    handleChangeYears,
    handleClear,
    handleApplyFilter,
    handleClearCityList,
    handleClearGifts,
    handleToggleExpandFilters,
    handleChangeCounty,
    handleClearCountyAndCity,
    handleUpdateNoteItem,
} = foundationProfileSlice.actions;

export default foundationProfileSlice.reducer;
