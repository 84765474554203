//v1 paths
export const FOUNDATION_PATH = 'main/foundation-profile';
export const FOUNDATION_CA_PATH = 'main/foundation-profile-ca';

//search engines
export const GIVING_HISTORY_PATH = 'main/search/by-giving-history';
export const GIVING_HISTORY_CA_PATH = 'main/search/giving-history-ca';
export const KEYWORD_PATH = 'main/search/by-keyword';
export const NAME_PATH = 'main/search/by-name';
export const NAME_CA_PATH = 'main/search/name-ca';
export const DIRECTOR_PATH = 'main/search/by-director';
export const DIRECTOR_CA_PATH = 'main/search/directors-ca';
export const YEAR_PATH = 'main/search/by-year';
export const YEAR_CA_PATH = 'main/search/year-ca';
export const CUSTOM_PATH = 'main/search/by-custom';
export const INTERNATIONAL_FUNDING_PATH = 'main/search/by-international-funding';

//v2 paths
export const FOUNDATION_V2_PATH = 'main/foundation-profile-v2';
export const FOUNDATION_CA_V2_PATH = 'main/foundation-profile-ca-v2';
export const FAVORITES_PATH = '/main/manage-funders';
export const CUSTOM_PROFILE_PATH = 'main/custom-profile';

//search engines
export const GIVING_HISTORY_V2_PATH = 'main/search/giving-history-v2';
export const GIVING_HISTORY_CA_V2_PATH = 'main/search/giving-history-ca-v2';
export const KEYWORD_V2_PATH = 'main/search/by-keyword-v2';
export const NAME_V2_PATH = 'main/search/by-name-v2';
export const NAME_CA_V2_PATH = 'main/search/name-ca-v2';
export const DIRECTOR_V2_PATH = 'main/search/by-director-v2';
export const DIRECTOR_CA_V2_PATH = 'main/search/directors-ca-v2';
export const YEAR_V2_PATH = 'main/search/by-year-v2';
export const YEAR_CA_V2_PATH = 'main/search/year-ca-v2';
export const CUSTOM_V2_PATH = 'main/search/by-custom-v2';
export const INTERNATIONAL_FUNDING_V2_PATH = 'main/search/by-international-funding-v2';


