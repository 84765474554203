import { useEffect } from 'react';
import * as Constants from "utils/constants";


export const PathKeys = Object.freeze({
    byGivingHistory: "by-giving-history",
    byKeyword: "by-keyword",
    byName: "by-name",
    byDirector: "by-director",
    byYear: "by-year",
    byGivingHistoryCa: "by-giving-history-ca",
    byNameCa: "by-name-ca",
    byDirectorCa: "by-director-ca",
    byYearCa: "by-year-ca",
    byInternationalFunding: "by-international-funding",
    byCustom: "by-custom",
    foundationProfile: "foundation-profile",
    foundationProfileCa: "foundation-profile-ca",
});

/**
 * @param {string} name - Identifier.
 * @param {Function} composeUrl - A function that returns an object of type URL object based on the provided parameters.
 */
const useVersioning = (name, composeUrl) => {

    const paths = Object.freeze({
        [PathKeys.byGivingHistory]: {
            v1: Constants.GIVING_HISTORY_PATH,
            v2: Constants.GIVING_HISTORY_V2_PATH,
        },
        [PathKeys.byKeyword]: {
            v1: Constants.KEYWORD_PATH,
            v2: Constants.KEYWORD_V2_PATH,
        },
        [PathKeys.byName]: {
            v1: Constants.NAME_PATH,
            v2: Constants.NAME_V2_PATH,
        },
        [PathKeys.byDirector]: {
            v1: Constants.DIRECTOR_PATH,
            v2: Constants.DIRECTOR_V2_PATH,
        },
        [PathKeys.byYear]: {
            v1: Constants.YEAR_PATH,
            v2: Constants.YEAR_V2_PATH,
        },
        [PathKeys.byGivingHistoryCa]: {
            v1: Constants.GIVING_HISTORY_CA_PATH,
            v2: Constants.GIVING_HISTORY_CA_V2_PATH,
        },
        [PathKeys.byNameCa]: {
            v1: Constants.NAME_CA_PATH,
            v2: Constants.NAME_CA_V2_PATH,
        },
        [PathKeys.byDirectorCa]: {
            v1: Constants.DIRECTOR_CA_PATH,
            v2: Constants.DIRECTOR_CA_V2_PATH,
        },
        [PathKeys.byYearCa]: {
            v1: Constants.YEAR_CA_PATH,
            v2: Constants.YEAR_CA_V2_PATH,
        },
        [PathKeys.byInternationalFunding]: {
            v1: Constants.INTERNATIONAL_FUNDING_PATH,
            v2: Constants.INTERNATIONAL_FUNDING_V2_PATH,
        },
        [PathKeys.byCustom]: {
            v1: Constants.CUSTOM_PATH,
            v2: Constants.CUSTOM_V2_PATH,
        },
        [PathKeys.foundationProfile]: {
            v1: Constants.FOUNDATION_PATH,
            v2: Constants.FOUNDATION_V2_PATH,
        },
        [PathKeys.foundationProfileCa]: {
            v1: Constants.FOUNDATION_CA_PATH,
            v2: Constants.FOUNDATION_CA_V2_PATH,
        }
    });

    const comparePaths = (path1, path2) => {
        const parts1 = path1.replace(/^\/|\/$/g, '').split('/');
        const parts2 = path2.replace(/^\/|\/$/g, '').split('/');
      
        let isSame = true;
      
        // Compare each part
        const maxLength = Math.max(parts1.length, parts2.length);
        for (let i = 0; i < maxLength; i++) {
          const part1 = parts1[i] || '';
          const part2 = parts2[i] || '';
          if (part1 !== part2) {
            isSame = false;
          }
        }
      
        return isSame;
    }

    const getPathByName = (name) => {
        let version = localStorage.getItem(name);
        if(version === null) return null;

        return paths[name][version];
    }

    const toggleVersion = () => {
        if(name === null || name === undefined)
            return;

        let toVersion = "v1";
        let currentVersion = localStorage.getItem(name);

        if(currentVersion !== null) 
            toVersion = currentVersion === "v1" ? "v2" : "v1";

        localStorage.setItem(name, toVersion);

        window.location.replace(typeof composeUrl === "function" 
            ? composeUrl(paths[name][toVersion])
            : new URL(paths[name][toVersion], window.location.origin));
    }

    useEffect(() => {
        if(name === null || name === undefined)
            return;

        const value = localStorage.getItem(name);
        const urlParams = new URLSearchParams(window.location.search);
        const qualifyFunders = urlParams.get('qualify');

        if(value === null) return;

        if (qualifyFunders === 'true') return;

        const targetPath = typeof composeUrl === "function" 
        ? composeUrl(paths[name][value])
        : new URL(paths[name][value], window.location.origin);

        const isSame = comparePaths(window.location.pathname, targetPath.pathname);

        if(!isSame) {
            window.location.replace(targetPath);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { toggleVersion, getPathByName }
}

export default useVersioning;