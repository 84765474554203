import PropTypes from 'prop-types';
import { useLocation, matchRoutes } from "react-router-dom";

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth } from 'store/constant';
import * as Constants from "utils/constants";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const location = useLocation();
    const foundationProfileRoute = [{ path: `/${Constants.FOUNDATION_V2_PATH}/:profileId` }];
    const match = matchRoutes(foundationProfileRoute, location);

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        color: 'white',
                        marginTop: match ? '40px' : null
                    }}
                    id="side-bar-1"
                >
                    <MenuList />
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                id="drawer-1"
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        //background: theme.palette.mode === 'dark' ? theme.palette.background.default : `linear-gradient(180deg, ${theme.palette.primary.dark}  0%, ${theme.palette.secondary.dark} 100%)`,
                        background: theme.palette.mode === 'dark' ? theme.palette.background.default : `linear-gradient(0deg, #389ff8 0%, #4e9206 100%)`, //theme.palette.primary.dark,
                        boxShadow: '1px 1px 10px 0px #363636',
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        },
                        zIndex: 1000,
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
