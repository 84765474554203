import { createSlice } from "@reduxjs/toolkit";
import { inquiryLetterApi } from "Services/InquiryLetterService";

export const initialState = {
    id: null,
    userProject: {
        id: '',
        value: '',
        label: ''
    },
    activeStep: 0,
    inquiryDocument: {
        introduction: '',
        describeOrganization: '',
        needStatement: '',
        methodology: '',
        otherFunding: '',
        summary: ''
    },
    stepList: [],
    forceDisabled: false,
    autosaveEnabled: false,
    timeoutId: null,
};

const inquirySlice = createSlice({
    name: "document-generator-inquiry",
    initialState,
    reducers: {
        handleChangeUserProject: (state, action) => {
            state.userProject = action.payload;
        },
        handleStep: (state, action) => {
            state.activeStep = action.payload;
        },
        handleChangeDocument: (state, action) => {
            if (action.payload.document.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                state.inquiryDocument[action.payload.stepName] = null;
            }
            else {
                state.inquiryDocument[action.payload.stepName] = action.payload.document;
            }
        },
        handleSaveSuccess: (state, action) => {
            state.id = action.payload.data;
        },
        handleAutoSaveEnabled: (state, action) => {
            state.autosaveEnabled = action.payload;
        },
        handleClear: (state, action) => {
            state.id = null;
            state.activeStep = 0;
            state.userProject.id = '';
            state.userProject.value = '';
            state.userProject.label = '';
            state.autosaveEnabled = false;
            state.inquiryDocument.introduction = "";
            state.inquiryDocument.describeOrganization = "";
            state.inquiryDocument.needStatement = "";
            state.inquiryDocument.methodology = "";
            state.inquiryDocument.otherFunding = "";
            state.inquiryDocument.summary = "";
        },
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            inquiryLetterApi.endpoints.getStepList.matchFulfilled, (state, action) => {
                state.stepList = action.payload;
            }
        )
        .addMatcher(
            inquiryLetterApi.endpoints.getInquiryLetter.matchFulfilled, (state, action) => {
                state.id = action.payload.id;
                state.inquiryDocument.introduction = action.payload.introduction;
                state.inquiryDocument.describeOrganization = action.payload.describeOrganization;
                state.inquiryDocument.needStatement = action.payload.needStatement;
                state.inquiryDocument.methodology = action.payload.methodology;
                state.inquiryDocument.otherFunding = action.payload.otherFunding;
                state.inquiryDocument.summary = action.payload.summary;
                state.inquiryDocument.notesQuantity = action.payload.notesQuantity;
            }
        )
    }

});

export const { 
    handleChangeUserProject,
    handleStep,
    handleChangeDocument,
    handleSaveSuccess,
    handleAutoSaveEnabled,
    handleClear,
} = inquirySlice.actions;

export default inquirySlice.reducer;
