import { createSlice } from "@reduxjs/toolkit";
import { customLetterApi } from "Services/CustomLetterService";

export const initialState = {
    id: null,
    userProject: {
        id: '',
        value: '',
        label: ''
    },
    userProjectToChange: {
        id: null,
        value: null,
        label: null
    },
    allProjectList: [],
    activeStep: 0,
    customDocument: {
        template: '',
        letter: ''
    },
    stepList: [],
    forceDisabled: false,
    autosaveEnabled: false,
};

const customSlice = createSlice({
    name: "document-generator-custom",
    initialState,
    reducers: {
        handleChangeUserProject: (state, action) => {
            state.userProject = action.payload;
        },
        handleStep: (state, action) => {
            state.activeStep = action.payload;
        },
        handleChangeDocument: (state, action) => {
            if (action.payload.document.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                state.customDocument[action.payload.stepName] = null;
            }
            else {
                state.customDocument[action.payload.stepName] = action.payload.document;
            }
        },
        handleSaveSuccess: (state, action) => {
            state.id = action.payload.data;
        },
        handleAutoSaveEnabled: (state, action) => {
            state.autosaveEnabled = action.payload;
        },
        handleClear: (state, action) => {
            state.id = null;
            state.activeStep = 0;
            state.userProject.id = '';
            state.userProject.value = '';
            state.userProject.label = '';
            state.autosaveEnabled = false;
            state.customDocument.template = "";
            state.customDocument.letter = "";
        },
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            customLetterApi.endpoints.getCustomStepList.matchFulfilled, (state, action) => {
                state.stepList = action.payload;
            }
        )
        .addMatcher(
            customLetterApi.endpoints.getCustomLetter.matchFulfilled, (state, action) => {
                state.id = action.payload.id;
                state.customDocument.template = action.payload.template;
                state.customDocument.letter = action.payload.letter;
            }
        )
    }

});

export const { 
    handleChangeUserProject,
    handleStep,
    handleChangeDocument,
    handleSaveSuccess,
    handleAutoSaveEnabled,
    handleClear,
} = customSlice.actions;

export default customSlice.reducer;
