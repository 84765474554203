import { createSlice } from "@reduxjs/toolkit";
import { impactReportLetterApi } from "Services/ImpactReportLetterService";

export const initialState = {
    id: null,
    userProject: {
        id: '',
        value: '',
        label: ''
    },
    activeStep: 0,
    impactReportDocument: {
        introduction: '',
        projectActivities: '',
        impact: '',
        financialReport: '',
        summary: '',
        appendice: '',
    },
    stepList: [],
    forceDisabled: false,
    autosaveEnabled: false,
    timeoutId: null,
};

const impactReportSlice = createSlice({
    name: "document-generator-impact-report",
    initialState,
    reducers: {
        handleChangeUserProject: (state, action) => {
            state.userProject = action.payload;
        },
        handleStep: (state, action) => {
            state.activeStep = action.payload;
        },
        handleChangeDocument: (state, action) => {
            if (action.payload.document.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                state.impactReportDocument[action.payload.stepName] = null;
            }
            else {
                state.impactReportDocument[action.payload.stepName] = action.payload.document;
            }
        },
        handleSaveSuccess: (state, action) => {
            state.id = action.payload.data;
        },
        handleAutoSaveEnabled: (state, action) => {
            state.autosaveEnabled = action.payload;
        },
        handleClear: (state, action) => {
            state.id = null;
            state.activeStep = 0;
            state.userProject.id = '';
            state.userProject.value = '';
            state.userProject.label = '';
            state.autosaveEnabled = false;
            state.impactReportDocument.introduction = "";
            state.impactReportDocument.projectActivities = "";
            state.impactReportDocument.impact = "";
            state.impactReportDocument.financialReport = "";
            state.impactReportDocument.summary = "";
            state.impactReportDocument.appendice = "";
        },
        handleFillAllProjects: (state, action) => {
            const { selectedProject, projects } = action.payload;
            state.forceDisabled = action.payload.length === 0;
            state.autosaveEnabled = false;
            state.allProjectList = projects ?? [];
            state.userProject = selectedProject;
        },
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            impactReportLetterApi.endpoints.getImpactReportStepList.matchFulfilled, (state, action) => {
                state.stepList = action.payload;
            }
        )
        .addMatcher(
            impactReportLetterApi.endpoints.getImpactReportLetter.matchFulfilled, (state, action) => {
                state.id = action.payload.id;
                state.impactReportDocument.introduction = action.payload.introduction;
                state.impactReportDocument.projectActivities = action.payload.projectActivities;
                state.impactReportDocument.impact = action.payload.impact;
                state.impactReportDocument.financialReport = action.payload.financialReport;
                state.impactReportDocument.summary = action.payload.summary;
                state.impactReportDocument.appendice = action.payload.appendice;
                state.impactReportDocument.notesQuantity = action.payload.notesQuantity;
            }
        )
    }

});

export const { 
    handleChangeUserProject,
    handleStep,
    handleChangeDocument,
    handleSaveSuccess,
    handleAutoSaveEnabled,
    handleClear,
} = impactReportSlice.actions;

export default impactReportSlice.reducer;
