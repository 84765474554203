export const TRANSLATIONS_EN_US = {
  labels: {
    user_name: 'Username',
    password: 'Password',
    reset_password: 'New Password',
    reset_password_confirm: 'Confirm Password',
    login: 'Login',
    sign_up: "Sign Up",
    forgot_password: "Forgot Password?",
    go_back: "Go Back",
    organization: "Organization",
    phone: "Phone",
    first_name: "First Name",
    last_name: "Last Name",
    address: "Address",
    select: "Select",
    country: "Country",
    complementary_address: "Address (line 2)",
    postal_code: "Zip Code",
    city: "City",
    state: "State",
    stateOrProvince: "State/Province",
    billing: "Billing",
    use_same_address: "Use Same Address",
    loading: "Loading...",
    register: "Register",
    message: "Message",
    error: "Error",
    welcome_back: "Hi, Welcome back",
    enter_credentials: "Enter your credentials to continue",
    dont_have_account: "Don't have account?",
    remember_me: "Remember me",
    sign_in: "Sign in",
    already_have_account: "Already have an account?",
    subscription_plan: "Subscription Plan",
    country_billing: "Country Billing",
    password_recovery: "Password recovery",
    password_reset: "Password Reset",
    setting_up_account: "Setting up account",
    current_password: "Current Password",
    new_password: "New Password",
    confirm_password: "Confirm Password",
    confirming_account: "Confirming account",
    accounts_created: "Accounts created",
    of: 'of',
    search: "Search",
    status: "Status",
    actions: "Actions",
    add_new_user: "Add new user",
    edit_user: "Edit user",
    additional_emails: "Additional E-mails",
    organization_letterhead: "Organization Letterhead",
    company_information: "Organization Information",
    company_organization: "Organization",
    created_at: "Created at",
    change_main_email: "Change main email address",
    main_email: "Main e-mail address",
    emails_created: "E-mails created",
    add_new_additional_email: "Add new additional e-mail",
    new_email_address: "New e-mail address",
    add_digital_letterhead: "Add digital letterhead",
    select_header_style: "Select letter style",
    header_only: "Header only",
    header_and_footer: "Header and Footer",
    email_alert: "E-mail alert",
    signature: "Signature",
    user_letterhead: "User Letterhead",
    user_information: "User Information",
    position: "Position",
    enable_disable_notifications: "Enable / Disable notifications",
    user_name_or_email: "Username or e-mail"
  },
  errors: {
    model_error: 'Check for errors and try again',
    general_error: 'An error has occurred',
    invalid_token: 'Invalid token',
    confirming_account_error: "Sorry, there's a problem activating your account",
    invalid_image: "Invalid image",
    inform_crop_area: "Size Image to conform to required dimensions",
    funder_not_found: "Foundation not found"
  },
  success: {
    logout_success: 'User logout successfully'
  },
  messages: {
    password_recovery_message: "To have a new password sent to you enter the username and email address you used to register",
    inform_new_password_message: "Please enter your new password",
    setting_up_account_message: "For activate your account, please enter the password and confirm",
    confirming_account_wait: "Please wait while we confirm your account",
    first_letterhead_message: "- Letterhead should be approximately 700 x 200 px.",
    second_letterhead_message: "- Supported formats are .GIF, .png, .jpeg and .jpg.",
    third_letterhead_message: "- Your organization's letterhead will appear at the top of all documents.",
    funders_not_selected_message: 'Please select at least one funder before continuing'
  },
  user_menu: {
    profile: 'Profile',
    logout: 'Logout',
  },
  side_menu: {
    administration: 'Administration',
    manage_account: 'Manage Account',
    manage_announcement: 'Announcement',
    manage_subscription_plan: 'Subscription Plan',
    manage_coupons: 'Coupons',
    manage_feedbacks: 'Feedbacks',
    manage_deadlines: 'Deadlines',
    manage_plans: 'Manage Plans',
    account: 'Account',
    user_profile: 'User Profile',
    additional_users: 'Additional Users',
    organization_profile: 'Organization Profile',
    project_manager: 'Project Manager',
    project_list: 'Projects',
    project_archive: 'Archived Projects',
    search_engine: "Search Engine",
    by_giving_history: 'By Giving History',
    by_name: 'By Name',
    by_director: 'By Director',
    by_year: 'By Year',
    by_rating: 'By Rating',
    by_custom: 'By Custom',
    document_generator: 'Document Generator',
    inquiry: 'Inquiry',
    proposal: 'Proposal',
    budget: 'Budget',
    custom_letter: 'Custom Letter',
    grant_secretary: 'Grant Secretary - Print',
    manage_funders: 'Manage Funders',
    favorites: 'Favorites',
    blocked_funders: 'Blocked',
    custom_databases: 'Custom Databases',
    by_keyword: 'By Keywords',
    by_international_funding: 'International Funding (PRO)',
    thank_you: 'Thank You',
    impact_report: 'Impact Report',
  },
  buttons: {
    create_account: "Create account",
    send: "Send",
    reset_password: "Reset Password",
    add_user: "Add User",
    insert_user: "Insert User",
    save_changes: "Save Changes",
    update_profile: "Update Profile",
    add_email: "Add E-mail",
    select_header_file: "Select header file",
    select_footer_file: "Select footer file",
    update_account: "Update Account",
  },
  accountCreated: "Account created successfully",
  accountDisabled: "This account is disabled. For more details contact the administrator.",
  accountDisabledSuccess: "Account disabled successfully",
  accountEnabledSuccess: "Account enabled successfully",
  accountNotActivated: "Your account is not active. Please check your email to activate your account",
  accountUpdatedSuccess: "Account updated successfully",
  additionalEmailNotFound: "Additional email not found",
  additionalEmailRemovedSuccess: "Additional email removed successfully",
  additionalEmailSavedSuccess: "Additional email saved successfully",
  additionalUserInsertSuccess: "Additional user added successfully",
  additionalUserUpdateSuccess: "Additional user updated successfully",
  addressBillingRequired: "Address billing is required",
  alertNotificationSavedSuccess: "Alert notification saved with success",
  cityBillingRequired: "The city billing is required",
  complementaryAddressBillingRequired: "Complementary address billing is required",
  confirmRegisterUserSuccess: "Thank you for confirming your email",
  countryBillingRequired: "The country billing is required",
  countryNotFound: "Country not found",
  emailExists: "Email address already exists",
  errorRegistration: "Model is empty",
  expirationDateSavedSuccess: "Expiration date saved with success",
  footerImageRequired: "The footer image is required",
  imageHeightLength: "Invalid image height",
  imageWidthLength: "Invalid image width",
  invalidEmailAddress: "Invalid email address",
  invalidLetterType: "Invalid letter type",
  invalidPhoneNumber: "Invalid phone number",
  invalidSubscriptionPlan: "Invalid subscription plan",
  invalidUserRole: "Invalid user role",
  letterheadSavedSuccess: "Letterhead saved with success",
  licenseExpired: "Your license has expired. For more details please contact the administrator",
  licenseNotFound: "No sufficient licenses are available. Please contact support@grantadvance.com",
  loginFailed: "Invalid user name or password",
  masterOnly: "Only master users have access to this resource",
  notEnoughLicense: "Your organization don't have any available license. If you want to add licenses please click here to purchase additional licenses",
  notificationExists: "Notification already exists",
  notificationNotFound: "Notification not found",
  funderQualifiedSuccess: "Funder qualified successfully",
  funderDisqualifiedSuccess: "Funder disqualified successfully",
  funderPausedSuccess: "Funder qualification successfully paused",
  notificationTypeNotFound: "Notification type not found",
  organizationDisabled: "This organization is disabled. For more details contact the administrator.",
  organizationMainAddressUpdatedSuccess: "Organization main address updated with success",
  organizationNotFound: "Organization not found",
  organizationProfileSavedSuccess: "Organization profile saved with success",
  passwordModified: "Password modified successfully",
  passwordRecovery: "Please check your email to reset your password.",
  passwordReset: "Password reset successfully",
  postalCodeBillingRequired: "The zip code is required",
  profileNotFound: "Profile not found",
  provinceOrStateBillingRequired: "The state is required",
  provinceOrStateNotFound: "State not found",
  sendActivationLinkSuccess: "Activation e-mail has been send successfully",
  sendPasswordResetSuccess: "Password reset e-mail has been send successfully",
  sendSetupPasswordSuccess: "Password setup e-mail has been send successfully",
  separatedLicenceExpirationDateError: "Expiration date of separated licenses cannot be greater than the expiration date of the main license",
  settingUpAccountSuccess: "Your account has been activated successfully. You can now login",
  signatureSavedSuccess: "Signature saved successfully",
  subscriptionPlanNotFound: "Subscription plan not found",
  userLogoutSuccess: "User logout successfully",
  userNameExists: "This User name is already taken",
  userNotAllowed: "User not allowed to login",
  userNotFound: "User not found",
  userProfileSavedSuccess: "User profile saved with success",
  wrongCurrentPassword: "Incorrect current password",
  additionalUsersRequired: "The additional users is required",
  addressRequired: "The address is required",
  amountDaysRequired: "The amount days is required",
  amountLicensesRequired: "The amount of licenses is required",
  planAmountRequired: "The plan amount is required",
  amountMonthsRequired: "The amount of months is required",
  amountYearsRequired: "The amount of years is required",
  billingAddressLine2Required: "The complementary billing address is required",
  billingAddressRequired: "The billing address is required",
  billingCityRequired: "The billing city is required",
  billingCountryRequired: "The country billing is required",
  billingPostalCodeRequired: "The zip code is required",
  billingProvinceOrStateRequired: "The billing state is required",
  cityRequired: "The city is required",
  companyNameRequired: "The company name is required",
  businessEinRequired: "BN/EIN is required",
  compareConfirmPassword: "The password and confirmation password do not match.",
  complementaryAddressRequired: "The complementary address is required",
  confirmPasswordRequired: "The confirmation password is required",
  countryRequired: "The country is required",
  displayPlanRequired: "The display plan is required",
  emailRequired: "The email is required",
  firstNameRequired: "The first name is required",
  headerImageRequired: "The header image is required",
  invalidEmail: "This email is invalid",
  invalidRole: "Invalid user role",
  lastNameRequired: "The last name is required",
  licenseAmountRange: "The number of licenses must be greater than zero",
  licenseRequired: "The license is required",
  newPasswordRequired: "The new password is required",
  notificationTypeRequired: "The Notification type is required",
  oldPasswordRequired: "The old password is required",
  organizationIdRequired: "The organization id is required",
  passwordLength: "The {0} must be at least {2} and at max {1} characters long.",
  passwordRequired: "The password is required",
  phoneRequired: "The phone number is required",
  planDescriptionRequired: "The plan description is required",
  postalCodeRequired: "The zip code is required",
  provinceOrStateRequired: "The state is required",
  roleRequired: "The role is required",
  signatureRequired: "The signature is required",
  subscriptionPlanNameRequired: "The subscription plan is required",
  tokenRequired: "The token is required",
  userIdRequired: "UserId is required",
  userNameRequired: "The login e-mail is required",
  planNameRequired: "Internal plan name is required",
  customLetterRequired: "The custom letter is required",
  customLetterSavedSuccess: "Custom letter saved with success",
  customTagDeleteSuccess: "Custom tag removed successfully",
  customTagInsertSuccess: "Custom tag created successfully",
  customTagNotFound: "Custom tag not found",
  customTagUpdateSuccess: "Custom tag updated successfully",
  documentStateInsertSuccess: "Document state created successfully",
  inquiryLetterRequired: "The inquiry letter is required",
  inquiryLetterSavedSuccess: "Inquiry letter saved with success",
  proposalLetterRequired: "The proposal letter is required",
  proposalLetterSavedSuccess: "Proposal letter saved with success",
  userProjectRequired: "The user project is required",
  customDocumentTypeRequired: "The document type is required",
  documentAmountRequired: "The amount is required",
  documentFlaggedRequired: "The date is required",
  documentStateRequired: "The document state is required",
  tagDescriptionRequired: "The tag description is required",
  tagNameRequired: "The tag name is required",
  blockedFundersDeleteAllSuccess: "All funders unblocked successfully",
  blockedFundersDeleteSuccess: "Funder unblocked successfully",
  blockedFundersInsertSuccess: "Funder blocked successfully",
  blockedFundersNotFound: "Blocked funder not found",
  customDatabaseDeleteSuccess: "Custom database successfully removed",
  customDatabaseFieldsMapRequired: "Please. Configure all the required fields before continue",
  customDatabaseNotFound: "Custom database not found",
  customDatabaseUserFieldsEmpty: "The list of configuration fields is empty",
  favoriteDeleteSuccess: "Favorite deleted successfully",
  favoriteDuplicateSuccess: "Favorite duplicated successfully",
  favoriteFundersDeleteSuccess: "Favorite funder deleted successfully",
  favoriteFundersInsertSuccess: "{{funders}} funders added to {{favCount}} favorites list",
  favoriteFundersNotFound: "Favorite funder not found",
  favoriteFundersUpdateSuccess: "Favorite funder update successfully",
  favoriteInsertSuccess: "Favorite created successfully",
  favoriteNotFound: "Favorite not found",
  favoriteUpdateSuccess: "Favorite update successfully",
  fundersListEmpty: "The funders list is empty",
  noteDeleteSuccess: "Note deleted successfully",
  noteInsertSuccess: "Note created successfully",
  noteNotFound: "Note not found",
  noteUpdateSuccess: "Note updated successfully",
  noteRequired: "Note is required before attaching any documents",
  notificationDateRequired: "The notification date is required",
  emailListRequired: "Select the e-mail",
  customDatabaseFileRequired: "The excel file is required",
  favoriteNameRequired: "The favorite name is required",
  favoriteRequired: "The favorite is required",
  foundationRequired: "The foundation is required",
  jsonUserFieldsMapRequired: "Please. Configure all the required fields before continue",
  labelTypeRequired: "The type of label is required",
  letterTypeRequired: "Select the type of document for print",
  noteDescriptionRequired: "The description is required",
  noteTitleRequired: "The title is required",
  bundleInsertSuccess: "Bundle created successfully",
  bundleNotFound: "Bundle not found",
  bundleTaskCount: "Enter at least one task",
  bundleTaskNotFound: "One or more task cannot be found",
  flagDateRequired: "The date is required",
  flagUpdateSuccess: "Flag updated successfully",
  projectBudgetCategoryDescriptionRequired: "The category description is required",
  projectBudgetDeleteSuccess: "Budget removed successfully",
  projectBudgetDescriptionExists: "This description already exists",
  projectBudgetInsertSuccess: "Budget added successfully",
  projectBudgetNotFound: "Budget not found",
  projectBudgetResetSuccess: "Budget reseted successfully",
  projectBudgetUpdateSuccess: "Budget updated successfully",
  projectEndDateRequired: "The end date is required",
  projectFundersDeleteSuccess: "Project Funder removed successfully",
  projectFundersEmpty: "Please select at least one funder before continuing",
  projectFundersInsertSuccess: "{{funderAmount}} funders added to {{projectName}}",
  projectStartDateRequired: "The start date is required",
  taskDeleteSuccess: "Task removed successfully",
  taskEditSuccess: "Task updated successfully",
  taskInsertSuccess: "Task created successfully",
  taskListEmpty: "The task list is empty",
  taskNotFound: "Task not found",
  userProjectArchivedSuccess: "User project archived successfully",
  userProjectDeleteSuccess: "User project deleted successfully",
  userProjectEndDateRequired: "The end date is required",
  userProjectInsertSuccess: "User project created successfully",
  userProjectListEmpty: "Select one or more projects",
  userProjectNotFound: "Project not found",
  userProjectStartDateInvalid: "The start date is invalid",
  userProjectStartDateRequired: "The start date is required",
  userProjectUnarchivedSuccess: "User project unarchived successfully",
  userProjectUpdateSuccess: "User project updated successfully",
  budgetTypeInvalid: "The budget type is invalid",
  bundleDescriptionRequired: "The bundle description is required",
  bundleNameRequired: "The bundle name is required",
  bundleTaskRequired: "The task list is required",
  flagAmountInvalid: "The amount is invalid",
  flagAmountRequired: "The amount is required",
  flagStatusRequired: "The status is required",
  foundationNameRequired: "The foundation name is required",
  foundationSourceTypeRequired: "The foundation source is required",
  profileIdRequired: "The profile is required",
  projectBudgetAmountRequired: "The budget amount is required",
  projectBudgetDescriptionRequired: "The budget description is required",
  projectCostInvalid: "The project cost is invalid",
  projectCostRequired: "The project cost is required",
  projectDescriptionRequired: "The project description is required",
  projectNameRequired: "The project name is required",
  projectStatusInvalid: "The project status is invalid",
  taskDescriptionRequired: "The task description is required",
  taskNameRequired: "The task name is required",
  foundationInsertSuccess: "Foundation added successfully",
  savedSearchDeleteSuccess: "Search deleted successfully",
  savedSearchDescriptionExists: "This description already exists",
  savedSearchInsertSuccess: "Search added successfully",
  savedSearchNotFound: "Saved search not found",
  savedSearchTypeInvalid: "The saved search type is invalid",
  savedSearchUpdateSuccess: "Search updated successfully",
  descriptionRequired: "The description is required",
  jsonFieldsRequired: "The search field is required",
  searchTypeRequired: "The search type is required",
  userProjectCompleted: "This Project is marked as completed",
  sendOnlineApplicationSuccess: "Online application message successfully sent",
  sendSuggestionSuccess: "Suggestion successfully sent",
  urlRequired: "The url is required",
  noteSubjectRequired: "The subject is required",
  accountDeleted: "This account was deleted. Contact the administrator",
  userRemovedSuccess: "Account removed successfully",
  userNameOrEmailRequired: "The User or e-mail name is required",
  fieldRequired: "This field is required",
  pickupNewPassword: "Please pick up new password",
  passwordMinCharacter: "Please enter at least {{minimumCharaters}} characters",
  enterSameValueAgain: "Please enter the same value again",
  feedbackSubmitted: "Feedback submitted successfully",
  feedbackInvalidGrantSize: "The grant size is invalid or too big",
  feedbackReviewUpdated: "Feedback review updated",
  announcementDescriptionRequired: "The announcement description is required",
  announcementReleaseDateRequired: "The announcement release date is required",
  announcementReleaseDateInvalid: "Past dates are not allowed for the Release date",
  announcementTypeRequired: "The announcement type is required",
  announcementNotFound: "Announcement not found",
  announcementInsertSuccess: "Announcement added successfully",
  announcementUpdateSuccess: "Announcement updated successfully",
  announcementDeleteSuccess: "Announcement deleted successfully",
  notificationNotFound: "Notification not found",
  couponNameRequired: "The coupon name is required",
  couponCodeRequired: "The coupon code is required",
  couponCodeFormatInvalid: "The coupon code is invalid",
  codeLengthInvalid: "Maximum of 15 characters",
  couponExpirationDateRequired: "The coupon expiration date is required",
  couponExpirationMaxValue: "The date cannot be more than 6 months from today",
  couponExpirationDateInvalid: "Invalid expiration date",
  couponSubscriptionPlanRequired: "The coupon subscription plan is required",
  couponExtraMonthsAmountInvalid: "The extra month only allow number 1 to 99",
  couponDiscountAmountInvalid: "The discount only allow number 1 to 90",
  couponCodeAlreadyExist: "The coupon code has already been redeemed",
  couponSubscriptionNotFound: "The subscription plan not found",
  couponNotFound: "Coupon not found",
  couponSelectType: "Please choose between Extra Time or Discount",
  couponInsertSuccess: "Coupon added succesfully",
  couponUpdateSuccess: "Coupon updated succesfully",
  couponDeleteSuccess: "Coupon deleted successfully",
  couponPauseUpdateSuccess: "Coupon updated successfully",
  deadlineEINRequired: "The EIN is required",
  deadlineFoundationNameRequired: "The foundation name is required",
  deadlineCategoryRequired: "The category is required",
  deadlineURLRequired: "The deadline URL is required",
  deadlineCountryRequired: "The country is required",
  deadlineDateInvalid: "Only allow future dates",
  deadlineAmountToobig: "The grant amount too big",
  deadlineImportSuccess: "{{fileName}} imported successfully",
  deadlineImportSuccessWithWarning: "The file was imported successfully. However, some items have invalid profile IDs. Please update them accordingly",
  deadlineUpdateSuccess: "Deadline updated successfully",
  deadlineDeleteSuccess: "Deadline deleted successfully",
  thankYouLetterSavedSuccess: "Thank you letter saved with success",
  impactReportLetterSavedSuccess: "Impact report letter saved with success",
  historyTypeRequired: "The history type is required",
  startDateRequired: "Start Date is required",
  startDateInvalid: "Please select a date that is later than today",
  dateMin:"Please select a date higher than today",
  referralNameCharityRequired: "Name charity is required",
  referralNameContactRequired: "Name contact is required",
  referralSubmitted: "Referral submitted successfully. Refer Another Charity",
  templateApplying: "Applying please wait...",
  templateApplyWarning: "Applying this may take a few minutes to complete",
};

