import { createSlice } from "@reduxjs/toolkit";
import { proposalLetterApi } from "Services/ProposalLetterService";

export const initialState = {
    id: null,
    userProject: {
        id: '',
        value: '',
        label: ''
    },
    activeStep: 0,
    proposalDocument: {
        coverLetter: '',
        executiveSummary: '',
        needStatement: '',
        goals: '',
        methods: '',
        evaluation: '',
        funding: '',
        organization: '',
        closingStatement: '',
    },
    stepList: [],
    forceDisabled: false,
    autosaveEnabled: false,
};

const proposalSlice = createSlice({
    name: "document-generator-proposal",
    initialState,
    reducers: {
        handleChangeUserProject: (state, action) => {
            state.userProject = action.payload;
        },
        handleStep: (state, action) => {
            state.activeStep = action.payload;
        },
        handleChangeDocument: (state, action) => {
            if (action.payload.document.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                state.proposalDocument[action.payload.stepName] = null;
            }
            else {
                state.proposalDocument[action.payload.stepName] = action.payload.document;
            }
        },
        handleSaveSuccess: (state, action) => {
            state.id = action.payload.data;
        },
        handleAutoSaveEnabled: (state, action) => {
            state.autosaveEnabled = action.payload;
        },
        handleClear: (state, action) => {
            state.id = null;
            state.activeStep = 0;
            state.userProject.id = '';
            state.userProject.value = '';
            state.userProject.label = '';
            state.autosaveEnabled = false;
            state.proposalDocument = initialState.proposalDocument;
        },
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            proposalLetterApi.endpoints.getProposalStepList.matchFulfilled, (state, action) => {
                state.stepList = action.payload;
            }
        )
        .addMatcher(
            proposalLetterApi.endpoints.getProposalLetter.matchFulfilled, (state, action) => {
                state.id = action.payload.id;
                state.proposalDocument.coverLetter = action.payload.coverLetter;
                state.proposalDocument.executiveSummary = action.payload.executiveSummary;
                state.proposalDocument.needStatement = action.payload.needStatement;
                state.proposalDocument.goals = action.payload.goals;
                state.proposalDocument.methods = action.payload.methods;
                state.proposalDocument.evaluation = action.payload.evaluation;
                state.proposalDocument.funding = action.payload.funding;
                state.proposalDocument.organization = action.payload.organization;
                state.proposalDocument.closingStatement = action.payload.closingStatement;
                state.proposalDocument.notesQuantity = action.payload.notesQuantity;
            }
        )
    }

});

export const { 
    handleChangeUserProject,
    handleStep,
    handleChangeDocument,
    handleSaveSuccess,
    handleAutoSaveEnabled,
    handleClear,
} = proposalSlice.actions;

export default proposalSlice.reducer;
